@import "misc/variables";

.main-wrapper {
  width: 100%;
  height: 100vh;
  //background: linear-gradient(to bottom, $primary, $lightBlue);
  background: $primary;
}

#campaynWrapper {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.form-control {
  color: $primary;
  height: 50px;
  font-size: 14px;
  background: $ultraLightBlue;

  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $primary;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $primary;
    opacity: 1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $primary;
    opacity: 1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $primary;
  }

}

.form-group {
  span.has-error {
    color: $danger !important;
  }

  &.has-error {
    color: $danger;

    input {
      border: 2px solid $danger;

      &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: $danger;
      }
      &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $danger;
        opacity: 1;
      }
      &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $danger;
        opacity: 1;
      }
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $danger;
      }
    }
  }
}

.primary-bkg-color {
  background-color: $primary;
}

.small-button {
  width: 200px;
  height: 50px;
  font-size: 12px;
  color: $white;
  text-align: center;
  margin: 0 auto;
  padding: 15px;
  background-color: $primary;
  border: 1px solid $primary;
  cursor: pointer;
}

.small-button-inverse {
  width: 200px;
  height: 50px;
  font-size: 12px;
  color: $primary;
  text-align: center;
  margin: 0 auto;
  padding: 15px;
  background-color: transparent;
  //background-color: $white;
  border: 1px solid $primary;
  cursor: pointer;
  transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  overflow: hidden;
}

.cd-primary-nav {
  .cd-secondary-nav {
    > li {
      height: auto;
    }
  }
}

ul > li > a {
  cursor: pointer;
}

.blue-btn-background {
  position: absolute;
  background: $primary;
  width: 200px;
  height: 50px;
  left: 0px;
  top: 0;
  z-index: -222;
  transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
}
a.text-white:hover {
  color: white;
}
.text-white { color: white; }
.text-gray  { color: #6E7E89; }
.text-lblue  { color: #B1DBFF; }
.text-skyblue { color: #1D8EC6; }

.font-mallanna  { font-family: 'Mallanna', sans-serif; }
.font-lato      { font-family: 'Lato', sans-serif; }
.font-roboto    { font-family: 'Roboto', sans-serif; }

.font-bold      { font-weight: bold; }
.font-96        { font-size: 96px; }
.font-75        { font-size: 75px; }
.font-60        { font-size: 60px; }
.font-50        { font-size: 50px; }
.font-46        { font-size: 46px; }
.font-37        { font-size: 37px; }
.font-36        { font-size: 36px; }
.font-34        { font-size: 34px; }
.font-29        { font-size: 29px; }
.font-26        { font-size: 26px; }
.font-21        { font-size: 21px; }
.font-20        { font-size: 20px; }
.font-19        { font-size: 19px; }
.font-18        { font-size: 18px; }
.font-16        { font-size: 16px; }
.font-15        { font-size: 15px; }
.font-14        { font-size: 14px; }
.font-13        { font-size: 13px; }
.font-12        { font-size: 12px; }
.font-11        { font-size: 11px; }

ul#contactNums li {
  margin: 0 1px 0 0;
  //margin-left: 0.8rem;
  text-align: center;
  display: inline-block;
  float: none;
}

ul#contactNums li button {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 30px;
  font-weight: 500;
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
  color: #0089FF;
  z-index: 1;
  outline: 0;
  cursor: pointer;
}

ul#contactNums li button.activeContNum {
  color: #FFFFFF;
}

ul#contactNums li button span.contNumBg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  z-index: -1;
  border-radius: 20px;
}

ul#contactNums li button span.contNumBg.activeContNumBg {
  background-color: #0089FF;

  /*z-index: 1;*/
}

.prUpperHolder {
  text-align: center;
  width: 100%;
  float: left;
}

.priceContainer {
  width: 700px;
  display: block;
  margin: 0 auto;
}


@media (max-width: 720px) {
  .priceContainer {
    width: 100%;
  }
}

div.col-md-10.col-md-offset-1 {
  max-width: 2000px;
  margin: 0 auto;
  float: none;
}

.fullWidthNavWrapper {
  background-color: #0089FF;
  margin: 0;
  padding: 0;
}

.navWrapper {
  max-width: 2000px;
  margin: 0 auto;
  padding: 0;
  float: none;
}

.templateContainer {
  width: 100%;
  height: 300px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  border: 1px solid black;
}

.templateContainer:hover {
  background-color: #222;
}

.showTemplate {
  background-color: #ddd;
  color: #555;
  font-weight: bold;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid #ddd;
  bottom: 3%;
  left: 30%;
  position: absolute;
  display: none;
}

.showTemplate:hover {
  background-color: #ff0014;
  color: white;
  border: 1px solid #ff0014;
}

.templateContainer:hover .showTemplate {
  display: block;
}
