@import "variables";

// Reset UserAgent style sheets
* {
  margin: 0;
  padding: 0;
}
// Bootstrap overrides

// Buttons
.btn {

  padding: 15px 40px;
  font-size: 16px;

  @include mediaQuery("sm") {
    padding: 10px 20px;
    font-size: 14px;
  }

  &.btn-default {
    background-color: $default;
    color: $white;
  }

  &.btn-primary {
    background-color: $primary;
  }

  &.btn-info {
    background-color: $info;
  }

  &.btn-success {
    background-color: $success;
  }

  &.btn-warning {
    background-color: $warning;
  }

  &.btn-danger {
    background-color: $danger;
  }

  &.btn-light-blue {
    color: $white;
    background-color: $lightBlue;
  }

  &.btn-ultra-light-blue {
    background-color: $ultraLightBlue;
    border-color: $grey;
  }

  &.btn-grey {
    background-color: $grey;
  }

  &.btn-primary-inverse {
    background-color: $white;
    border: 1px solid;
    color: $primary;
  }
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  background-color: $primary !important;
}