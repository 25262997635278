@import "misc/mixins";
@import "misc/variables";

.auth-body {
  font-family: $font-family-mallanna; // Lato atm
}

.auth {

  .auth-box {
    margin: 0 auto;
    width: 480px;

    @include mediaQuery("xs") {
      width: 320px;
    }

    .right-side-text {
      float: right;
      width: 45%;
      margin-right: -55%;
      margin-top: -105%;
      color: #98D0FF;
    }

    .left-side-text {
      float: left;
      width: 45%;
      margin-left: -55%;
      margin-top: -105%;
      color: #98D0FF;

    }

  }

  .auth-panel {
    &.login {
      margin-top: 45%;

      @include mediaQuery("sm") {
        margin-top: 20%;
        margin-left: 5%;
        margin-right: 5%;
      }

      @include mediaQuery("xs") {
        margin-top: 30%;
        margin-left: 5%;
        margin-right: 5%;
      }
    }

    &.register {
      margin-top: 30%;

      @include mediaQuery("sm") {
        margin-top: 20%;
        margin-left: 5%;
        margin-right: 5%;
      }

      @include mediaQuery("xs") {
        margin-top: 30%;
        margin-left: 5%;
        margin-right: 5%;
      }
    }

    .panel-title {
      margin-bottom: 40px;
    }

    .input-label {
      color: $primary;
      float:right;
      margin-right: 10px;
      margin-top: -36px;
    }

    .small-text {
      font-size: 12px;
      font-style: normal;
    }

    input {
      color: $black;
    }
  }

  .login-using {
    color: $info;
    font-family: $font-family-sans-serif2;
    font-size: 12px;
    text-align: center;

    ul {
      list-style-type: none;

      li {
        display: inline-block;

        &:after {
          content: " | ";
        }

        &:last-child:after {
          content: '';
        }

        a {
          text-decoration: none;
          color: $info;

          &:hover {
            text-decoration: underline;
            color: $ultraLightBlue;
          }
        }
      }
    }

    a {
      color: $white;
    }
  }

  .bottom-logo {
    text-align: center;
    margin-top: 50px;

    .logo {
      img {
        width: 130px;
      }
    }
  }

  .sign-up-bottom-logo {
    text-align: center;
    margin-top: 20px;

    .logo {
      img {
        width: 130px;
      }
    }
  }

}
