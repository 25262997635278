@import "misc/variables";
@import "misc/mixins";

.blue-sky-bkg {
  //background: $primary;
  //background: -webkit-linear-gradient($primary, $info); /* For Safari 5.1 to 6.0 */
  //background: -o-linear-gradient($primary, $info); /* For Opera 11.1 to 12.0 */
  //background: -moz-linear-gradient($primary, $info); /* For Firefox 3.6 to 15 */
  //background: linear-gradient($primary, $info); /* Standard syntax */
  width: 100%;
  height: 70vh;

  h1 {
    margin-top: 150px;
    text-align: center;
    color: #000000;
    font-size: 50px;
    font-family: $font-family-mallanna;

    @include mediaQuery("sm") {
      margin-top: 120px;
      font-size: 35px;
    }
  }

  .clouds {
    margin-top: 40vh;
    width: 100%;
    height: 30%;
    position: fixed;
  }

  .center-img {
    z-index: 2;
  }
}

.btn-round-nav {
  border-radius: 80px;
  width: 25vh;
}

.btn-round {
  border-radius: 80px;
  min-width: 200px;
}

.img-full-width {
  width: 100%;
  height: 100%;

}

.txt-center {
  text-align: center;
}

.round-image {
  border-radius: 50%;
  width: 20%;
  margin: 0 auto;
  display: block;
}


.footer {
  color: $white;

  h4 {
    color: powderblue;
  }

  select {
    background-color: $primary;
    border: 1px solid white;
    width: 50%;
    height: 30px;
  }

  .form-control {
    height: 35px;
    width: 100%;
    color: $black;
    float: right;
    z-index: 1;
  }

  .input-label {
    border-radius: 3px;
    color: $white;
    background-color: $primary;
    text-align: center;
    width: 60px;
    padding: 1px 3px;
    float:right;
    margin: -28px 8px 2px 2px;
    z-index: 2;
  }

  a:hover {
    color: white;
  }
}
