@import "misc/mixins";
@import "misc/variables";

.email-settings-body {

  h1 {
    color: $primary;
    text-align: center;
    width: 100%;
  }

  .nav {
    margin-top: 40px;
  }

  .form-horizontal {
    margin-top: 30px;
  }

  input {
    .form-control {
      color: black;
    }
  }

  .btn.btn-primary {
    background-color: $primary;
  }

  .bottom-logo {
    text-align: center;

    .logo {
      img {
        width: 130px;
        margin-top: 70px;
      }
    }
  }

  .radio {
    label {
      color: black;
    }
  }

  .scrollbox {
    max-height: 400px;
    overflow-y: scroll;
  }
}
