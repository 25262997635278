//Margins
.m-0    { margin: 0}
.m-xxs  { margin: 5px }
.m-xs   { margin: 10px }
.m-sm   { margin: 15px }
.m-md   { margin: 20px }
.m-lg   { margin: 25px }
.m-xl   { margin: 30px }
.m-xxl  { margin: 50px }

.m-t-0    { margin-top: 0}
.m-t-xxs  { margin-top: 5px }
.m-t-xs   { margin-top: 10px }
.m-t-sm   { margin-top: 15px }
.m-t-md   { margin-top: 20px }
.m-t-lg   { margin-top: 25px }
.m-t-xl   { margin-top: 30px }
.m-t-xxl  { margin-top: 50px }

.m-b-0    { margin-bottom: 0}
.m-b-xxs  { margin-bottom: 5px }
.m-b-xs   { margin-bottom: 10px }
.m-b-sm   { margin-bottom: 15px }
.m-b-md   { margin-bottom: 20px }
.m-b-lg   { margin-bottom: 25px }
.m-b-xl   { margin-bottom: 30px }
.m-b-xxl  { margin-bottom: 50px }

.m-r-0    { margin-right: 0}
.m-r-xxs  { margin-right: 5px }
.m-r-xs   { margin-right: 10px }
.m-r-sm   { margin-right: 15px }
.m-r-md   { margin-right: 20px }
.m-r-lg   { margin-right: 25px }
.m-r-xl   { margin-right: 30px }
.m-r-xxl  { margin-right: 50px }

.m-l-0    { margin-left: 0}
.m-l-xxs  { margin-left: 5px }
.m-l-xs   { margin-left: 10px }
.m-l-sm   { margin-left: 15px }
.m-l-md   { margin-left: 20px }
.m-l-lg   { margin-left: 25px }
.m-l-xl   { margin-left: 30px }
.m-l-xxl  { margin-left: 50px }

// Paddings
.p-0    { padding: 0}
.p-xxs  { padding: 5px }
.p-xs   { padding: 10px }
.p-sm   { padding: 15px }
.p-md   { padding: 20px }
.p-lg   { padding: 25px }
.p-xl   { padding: 30px }
.p-xxl   { padding: 50px }

.p-t-0    { padding-top: 0}
.p-t-xxs  { padding-top: 5px }
.p-t-xs   { padding-top: 10px }
.p-t-sm   { padding-top: 15px }
.p-t-md   { padding-top: 20px }
.p-t-lg   { padding-top: 25px }
.p-t-xl   { padding-top: 30px }
.p-t-xxl   { padding-top: 50px }

.p-b-0    { padding-bottom: 0}
.p-b-xxs  { padding-bottom: 5px }
.p-b-xs   { padding-bottom: 10px }
.p-b-sm   { padding-bottom: 15px }
.p-b-md   { padding-bottom: 20px }
.p-b-lg   { padding-bottom: 25px }
.p-b-xl   { padding-bottom: 30px }
.p-b-xxl   { padding-bottom: 50px }

.p-r-0    { padding-right: 0}
.p-r-xxs  { padding-right: 5px }
.p-r-xs   { padding-right: 10px }
.p-r-sm   { padding-right: 15px }
.p-r-md   { padding-right: 20px }
.p-r-lg   { padding-right: 25px }
.p-r-xl   { padding-right: 30px }
.p-r-xxl   { padding-right: 50px }

.p-l-0    { padding-left: 0}
.p-l-xxs  { padding-left: 5px }
.p-l-xs   { padding-left: 10px }
.p-l-sm   { padding-left: 15px }
.p-l-md   { padding-left: 20px }
.p-l-lg   { padding-left: 25px }
.p-l-xl   { padding-left: 30px }
.p-l-xxl   { padding-left: 50px }