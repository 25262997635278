@mixin mediaQuery($bsColSize) {

  @if($bsColSize) {

    @if($bsColSize == "xxs"){
      @media (max-width: 480px) {  @content;  }
    }

    @if($bsColSize == "xs"){ 
      @media (max-width: 768px) {  @content;  }
    }

    @if($bsColSize == "sm"){
      @media (max-width: 768px) {  @content;  }
    }

    @if($bsColSize == "md"){
      @media (max-width: 992px) {  @content;  }
    }

    @if($bsColSize == "lg"){
      @media (max-width: 1200px) {  @content;  }
    }

  }
}

@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }

  // Output standard non-prefixed declaration
  #{$property}: $value;
}