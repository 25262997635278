// Main colors
$black:           #000000;
$white:           #ffffff;

// Bootstrap colors
$default:         #6E7E89; // Dark gray
$primary:         #0089FF; // DeepBlue
$success:         #84b547;
$info:            #65B8FF; // SkyBlue
$warning:         #FF9100;
$danger:          #FF3F00;

// Extra colors
$lightBlue:       #1AD1FD; // LightBlue
$ultraLightBlue:  #EDF6FD; // Ultra light blue
$darkGrey:        $default;// Dark gray | default
$grey:            #D6DBDE; // Light gray


// Typography

// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  'Lato', Arial, sans-serif;
$font-family-sans-serif2: 'Lato', Roboto, sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
$font-family-lato: 'Lato', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;
$font-family-mallanna: 'Mallana', sans-serif;


//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans-serif;

$font-size-base:          13px;
$font-size-large:         ceil(($font-size-base * 1.05)); // ~18px
$font-size-small:         ceil(($font-size-base * 0.75)); // ~12px

$font-size-h1:            floor(($font-size-base * 2.2)); // ~36px
$font-size-h2:            floor(($font-size-base * 1.9)); // ~30px
$font-size-h3:            ceil(($font-size-base * 1.5)); // ~24px
$font-size-h4:            ceil(($font-size-base * 1.2)); // ~18px
$font-size-h5:            $font-size-base;
$font-size-h6:            ceil(($font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.528571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit;
$headings-font-weight:    500;
$headings-line-height:    1.6;