@import "misc/variables";
@import "misc/mixins";

.header-div-text {
  height: 30vh;
  width: 100%;
  background: -webkit-linear-gradient($primary, $lightBlue); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradien($primary, $lightBlue); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient($primary, $lightBlue); /* For Firefox 3.6 to 15 */
  background: linear-gradient($primary, $lightBlue); /* Standard syntax */ 
  color: $white;

  h1 {
      margin: 0 !important;  
  }
  .page-content-head-txt {
      font-size: 80px;
      color: $black;
  }
  
}

i.font-3em {
  font-size: 3em !important;
}

.hidden-block {
  display: none;
}