
// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato|Roboto');

//noinspection CssUnknownTarget
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';

@import "misc/mixins";
@import "misc/variables";
@import "misc/bootstrap";   // Bootstrap overrides
@import "misc/helpers";   // Useful classes
//@import '/partials/variables'; // colors, fonts etc...
//@import '/partials/layout'; // responsive grid and media queries
//
//@import "style-navbar"; //navbar style
@import "auth";   // For auth page
@import "email";   // For email page
@import "home"; //home page
@import "features";
@import "style";